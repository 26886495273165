<svg width="99" height="34" viewBox="0 0 99 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.5703 11.0474H50.709V17.1684L56.4908 11.0474H59.131L54.0572 16.2835L59.3522 23.2157H56.7416L52.5969 17.7437L50.709 19.6906V23.2157H48.5703V11.0474Z" fill="#947CF7"/>
    <path d="M66.3882 11.0474H64.2495V23.201H66.3882V11.0474Z" fill="#947CF7"/>
    <path d="M72.0518 17.1685V17.139C72.0518 13.7171 74.6919 10.8557 78.4236 10.8557C82.1552 10.8557 84.7659 13.6876 84.7659 17.1095V17.139C84.7659 20.5609 82.1257 23.4223 78.3941 23.4223C74.6624 23.4223 72.0518 20.5904 72.0518 17.1685ZM82.5239 17.1685V17.139C82.5239 14.7791 80.7982 12.8174 78.3941 12.8174C75.9899 12.8174 74.2937 14.7496 74.2937 17.1095V17.139C74.2937 19.499 76.0194 21.4459 78.4236 21.4459C80.8277 21.4459 82.5239 19.5285 82.5239 17.1685Z" fill="#947CF7"/>
    <path d="M89.3975 21.4459L90.6807 19.912C91.8459 20.915 93.0259 21.4902 94.5156 21.4902C95.8283 21.4902 96.669 20.8707 96.669 19.9562V19.9267C96.669 19.0565 96.1823 18.5845 93.9256 18.0683C91.3444 17.4488 89.8842 16.6818 89.8842 14.4399V14.4104C89.8842 12.3307 91.6246 10.8853 94.0288 10.8853C95.7988 10.8853 97.2 11.4162 98.4389 12.4192L97.2885 14.0417C96.197 13.2304 95.1055 12.7879 93.9846 12.7879C92.7309 12.7879 92.0081 13.4369 92.0081 14.2334V14.2629C92.0081 15.2069 92.5686 15.6199 94.9138 16.1803C97.4802 16.7998 98.8077 17.729 98.8077 19.735V19.7645C98.8077 22.0359 97.023 23.3929 94.4713 23.3929C92.6129 23.3929 90.8577 22.7439 89.3975 21.4459Z" fill="#947CF7"/>
    <path d="M33.9977 0H0V33.9977H33.9977V0Z" fill="#947CF7"/>
    <mask id="mask0_504_166" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="34">
        <path d="M33.9977 0H0V33.9977H33.9977V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_504_166)">
        <path d="M14.4546 -1.63721H12.5519V35.1924H14.4546V-1.63721Z" fill="white"/>
        <path d="M31.9475 36.6527L3.77589 14.6316L35.2809 2.34521L35.9594 4.11516L7.56652 15.1921L33.1275 35.1482L31.9475 36.6527Z" fill="white"/>
        <path d="M14.7053 -0.132812H14.2333V0.44242H14.7053V-0.132812Z" fill="white"/>
        <path d="M14.7053 35.3695H14.2333V34.2486H14.7053V35.3695ZM14.7053 33.3194H14.2333V32.1984H14.7053V33.3194ZM14.7053 31.2692H14.2333V30.1482H14.7053V31.2692ZM14.7053 29.219H14.2333V28.098H14.7053V29.219ZM14.7053 27.1688H14.2333V26.0478H14.7053V27.1688ZM14.7053 25.1186H14.2333V23.9976H14.7053V25.1186ZM14.7053 23.0684H14.2333V21.9474H14.7053V23.0684ZM14.7053 21.0182H14.2333V19.8973H14.7053V21.0182ZM14.7053 18.968H14.2333V17.8471H14.7053V18.968ZM14.7053 16.9178H14.2333V15.7969H14.7053V16.9178ZM14.7053 14.8677H14.2333V13.7467H14.7053V14.8677ZM14.7053 12.8175H14.2333V11.6965H14.7053V12.8175ZM14.7053 10.7673H14.2333V9.64631H14.7053V10.7673ZM14.7053 8.71709H14.2333V7.59612H14.7053V8.71709ZM14.7053 6.6669H14.2333V5.54593H14.7053V6.6669ZM14.7053 4.61671H14.2333V3.49575H14.7053V4.61671ZM14.7053 2.56652H14.2333V1.44556H14.7053V2.56652Z" fill="white"/>
        <path d="M34.1895 17.2423H33.0685V16.7703H34.1895V17.2423ZM32.1393 17.2423H31.0183V16.7703H32.1393V17.2423ZM30.0891 17.2423H28.9681V16.7703H30.0891V17.2423ZM28.0389 17.2423H26.9179V16.7703H28.0389V17.2423ZM25.9887 17.2423H24.8678V16.7703H25.9887V17.2423ZM23.9385 17.2423H22.8176V16.7703H23.9385V17.2423ZM21.8883 17.2423H20.7674V16.7703H21.8883V17.2423ZM19.8382 17.2423H18.7172V16.7703H19.8382V17.2423ZM17.788 17.2423H16.667V16.7703H17.788V17.2423ZM15.7378 17.2423H14.6168V16.7703H15.7378V17.2423ZM13.6876 17.2423H12.5666V16.7703H13.6876V17.2423ZM11.6374 17.2423H10.5164V16.7703H11.6374V17.2423ZM9.58721 17.2423H8.46625V16.7703H9.58721V17.2423ZM7.53703 17.2423H6.41606V16.7703H7.53703V17.2423ZM5.48684 17.2423H4.36587V16.7703H5.48684V17.2423ZM3.43665 17.2423H2.31568V16.7703H3.43665V17.2423ZM1.38646 17.2423H0.265493V16.7703H1.38646V17.2423Z" fill="white"/>
    </g>
</svg>
